import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import MemberDetailsVIew from "../MemberView";
import styled from "styled-components";
import axios from "axios";


const CusButton = styled(Button)`
  background-color: #8cc741 !important;
  /* border: solid #8cc741;*/
  border: none;
  font-size: 12px;
  font-weight: bold;
  padding: 5px;
  margin-top: 20px;

  &:hover {
    background-image: linear-gradient(135deg, rgb(35, 172, 221) 30%, rgb(167, 191, 51) 100%);
    border-color: #8cc741;
  }
`;

const Capitalize = styled.span`
  text-transform: capitalize;
`;

const ModelheaderWrapper = styled.div`
  button {
    color: green !important;
    display: inline-block !important;
    border: 2px solid #018c8c !important;
    border-radius: 100% !important;
    background-color: #ffffff  !important;
    font-weight: bold !important;
    line-height: 19.5px  !important;
    width: 26px !important;
  }
`;

const ModalView = ({ member }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      <CusButton variant="primary" onClick={handleShow}>
        View Details
      </CusButton>
      <Modal show={show} onHide={handleClose} size="lg">
        <ModelheaderWrapper>
        <Modal.Header closeButton>
          <Modal.Title><Capitalize>{member.mepr_salutation}</Capitalize>.{' '}{member.first_name} {member.last_name}</Modal.Title>
        </Modal.Header>
        </ModelheaderWrapper>
        <Modal.Body>
          <MemberDetailsVIew member={member}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ModalView;
