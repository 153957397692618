import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import ModalView from "../Modal";
import 'bootstrap/dist/css/bootstrap.min.css';
import defaultImage from "../../Images/ISFGS-SurgeonPlaceholder.png";
const lookup = require('country-code-lookup')

const Title = styled.span`
  font-size: 1.2em;
  font-weight: bold;
`;

const Capitalize = styled.span`
  /* text-transform: uppercase; */
  text-transform: capitalize;
`;

const Contact = styled.span`
  color: #0d96b5;

  a {
    color: #0d96b5;
  }
`;

const Body = styled.span`
  font-size: 0.9em;
  line-height: 1em;
  word-spacing: normal;
`;

const MembersDetails = styled.div`
  @media screen and (max-width: 992px) {
    padding-top: 20px;

    .container {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }
  }
`;

const MemberImage = styled.div`
  /* padding: 20px; */

`;

const SearchResultList = ({ members }) => {
  const [zipCode, setZipCode] = useState();
  const [name, setName] = useState();

  const searchRef = useRef();

  // console.log('members', members);

  const normalizeInput = (value) => {
    // return nothing if no value
    if (!value) return value; 
  
    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length; 

    // console.log('value', value);
    // console.log('currentValue', currentValue);
    // console.log('cvLength', cvLength)

  
    if (value.length) {
  
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue; 
  
      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`; 
  
      // console.log('currentValue', currentValue);

      if (currentValue.slice(0, 1) === '1') {
        // console.log('IS 1');
        // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
        return `+${currentValue.slice(0, 1)} (${currentValue.slice(1, 4)}) ${currentValue.slice(4, 7)}-${currentValue.slice(7, 12)}`; 
      } 
      
      if (currentValue.slice(0, 1) !== '1') {
        // console.log('IS NOT 1');
        // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
        return `+1 (${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 11)}`; 
      }
  }
}

  return (
    <React.Fragment>
      <Container className="p-0" id="search" ref={searchRef}>
        { (members == false) ?
          <Row>
            <h2>No Results Found</h2>
            <p>There are no surgeons in 200 miles of your zip code search, or your search criteria doesn’t match any surgeons in our database.
            <br/>Please try different search criteria and try again. 
            <br/>
            <br/>Search Tips:
            <br/>
            </p>
            <ul>
              <li>Remove any specialty filters</li>
              <li>Remove the doctor's name</li>
              <li>Try a zip code within a major metropolitan area near you.</li>
            </ul>
           
            {/* <a href="https://isfgs.org/contact/" target="_blank">contact ISFSG</a> */}
          </Row>
        : ''}
        {members && (
          <Row>
            <h2>Search Results</h2>
          </Row>
        )}
        <Row>
          {members &&
            members.map((member, i) => (
              <Col xs={12} md={6} key={i}>
                <Container style={{ padding: "20px" }}>
                  <Row>
                    <Col xs={12} md={12} lg={4}>
                      <MemberImage>
                      {member && member.mepr_photo ? (
                        <img
                          src={member.mepr_photo}
                          alt="member image"
                          width={150}
                          // height={250}
                        />
                      ) : (
                        // : <img src="./images/ISFGS-SurgeonPlaceholder.png" alt="member image" width={250} height={250}/>
                        <img
                          src={defaultImage}
                          alt="member image"
                          width={150}
                          // height={250}
                        />
                      )}
                      </MemberImage>
                    </Col>
                    <Col xs={12} md={12} lg={8}>
                      <MembersDetails>
                      <Container>
                        <Row>
                          <Title>
                            <Capitalize>{member.mepr_salutation}.{" "}</Capitalize>
                            {member.first_name} {member.last_name}
                          </Title>
                        </Row>
                        <Row>
                          <p>
                            <Body>
                              {member.mepr_hospital_institution_company_name
                                ? member.mepr_hospital_institution_company_name +
                                  ", "
                                : ""}
                              {member.mepr_hospital_institution_company_name ? (
                                <br />
                              ) : (
                                ""
                              )}
                              {member.mepr_address_one}
                              {", "}
                              {member.mepr_address_two}
                              <br />
                              {member.mepr_address_city}
                              {", "}
                              {member.mepr_address_state}{" "}
                              {member.mepr_address_zip}
                              <br />
                              {member.country}
                            </Body>
                          </p>
                          <Contact>
                            Appointment:{" "}
                            <a href={`tel:${member.mepr_phone}`}>
                              {member.mepr_address_country === 'US' ? normalizeInput(member.mepr_phone) : member.mepr_phone }
                              {/* {normalizeInput(member.mepr_phone)} */}
                            </a>
                          </Contact>
                        </Row>
                        <ModalView member={member} />
                      </Container>
                      </MembersDetails>
                    </Col>
                  </Row>
                </Container>
              </Col>
            ))}
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default SearchResultList;
