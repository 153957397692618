import logo from './logo.svg';
// import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SearchView from './views/SearchView';
import SearchResultList from './views/MembersView';
import MainView from './views/Main';


function App({appParams}) {
  return (
    <div className="App">
      <header className="App-header">
       <MainView appParams={appParams} />
      </header>
    </div>
  );
}

export default App;
