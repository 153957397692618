import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faFlagUsa,
  faGlobe,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { Formik } from 'formik';
import * as Yup from 'yup';
import 'bootstrap/dist/css/bootstrap.min.css';
import CountrySelect from 'react-bootstrap-country-select';

import 'bootstrap/dist/css/bootstrap.css'; // or include from a CDN
import 'react-bootstrap-country-select/dist/react-bootstrap-country-select.css';

const Box = styled.div`
  padding: 0px 15px 35px 15px;
  background-color: #e6edde smaller {
    font-size: 5px;
  }
`;

const CustomButton = styled(Button)`
  background: #00b9ff;
  height: 2.5rem;
  padding: 0 12px 0 12px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  width: 100%;
  box-shadow: 2px 5px 5px #888888;

  @media screen and (min-width: 768px) {
    /* position: relative; */
    /* top: 30px; */
    margin: 20px 0px 40px 0;
  }

  @media screen and (max-width: 768px) {
    margin: 15px 0px 30px 0;
  }
`;

const CustomButtonAll = styled(Button)`
  background: #00b9ff;
  height: 2.5rem;
  padding: 0 12px 0 12px;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  color: #fff;
  width: 100%;
  box-shadow: 2px 5px 5px #888888;

  @media screen and (min-width: 768px) {
    /* position: relative; */
    /* top: 30px; */
    margin: 20px 0px 40px 0;
  }

  @media screen and (max-width: 768px) {
    margin: 0px 0px 30px 0;
  }
`;

const MYFORM = styled(Form)`
  width: 90%;
  text-align: left;
  padding-top: 2em;
  padding-bottom: 2em;

  @media (min-width: 786px) {
    width: 50%;
  }
`;

const SignupSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
});

const SearchView = ({ handleSubmit, formValues, appParams }) => {
  const [searchType, setSearchType] = useState('');
  const [value, setValue] = React.useState({
    alpha2: 'us',
    alpha3: 'usa',
    flag: '🇺🇸',
    id: 'us',
    ioc: 'usa',
    name: 'United States',
  });

  const submitForm = (e) => {
    handleSubmit(e);
  };

  const handleSubmitValue = (search) => {
    setSearchType(search);
  };

  const handleClearCountry = () => {
    console.log('TEST THIS', Math.random());
    setValue('');
    // e.target.reset();
  };

  useEffect(() => {}, [value]);

  return (
    <React.Fragment>
      <Container className='p-0'>
        <Row>
          <Col xs={12} lg='8'>
            <Container>
              <Row>
                <p>
                  {appParams.description}
                  {/* Enter your zip code below (U.S. only at this time) to find a
                  surgeon that uses Fluorescence Guided Surgery near you.
                  Optionally, search U.S. and Internationally by surgeon's last
                  name and/or filter speciality. */}
                </p>
              </Row>
              <Row>
                <Col md={12}>
                  <Formik
                    initialValues={{
                      zipcode: '',
                      lastName: '',
                      speciality: '',
                      // country: {id: 'us'},
                    }}
                    validationSchema={Yup.object().shape({
                      zipcode: Yup.string().min(5).max(5),
                      lastName: Yup.string().max(20),
                      // spesiality: Yup.string().max(255),
                    })}
                    onSubmit={async (
                      values,
                      { resetForm, setErrors, setStatus, setSubmitting }
                    ) => {
                      try {
                        const valuesPrepAPI = {
                          zipcode: values.zipcode,
                          lastName: values.lastName,
                          speciality: values.speciality,
                          search: searchType,
                        };

                        // console.log('valuesRefactored', valuesPrepAPI);
                        submitForm(valuesPrepAPI);

                        resetForm({ values: { ...values, zipcode: '' } });
                        setStatus({ success: true });
                        setSubmitting(true);
                      } catch (err) {
                        console.error(err);
                        setStatus({ success: false });
                        setErrors({ submit: err.message });
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      touched,
                      values,
                      setFieldValue,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <Row>
                          <Col xs={12} md={6}>
                            <Form.Group>
                              <Form.Label>Select Country</Form.Label>
                              <InputGroup className='mb-3'>
                                <Col>
                                <CountrySelect
                                  name='country'
                                  value={value}
                                  onChange={setValue}
                                  style={{
                                    width: '600px !important'
                                  }}
                                />
                                </Col>
                                <InputGroup.Text>
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    style={{
                                      width: '30px',
                                      fontSize: '20px',
                                      cursor: 'pointer',
                                      color: 'rgb(0, 20, 30)',
                                    }}
                                    onClick={handleClearCountry}
                                  />
                                </InputGroup.Text>
                              </InputGroup>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={6}>
                            <Form.Group
                              className='mb-3'
                              controlId='exampleForm.ControlInput1'
                            >
                              <Form.Label>Last Name</Form.Label>
                              <Form.Control
                                name='lastName'
                                type='text'
                                onChange={handleChange}
                                value={values.lastName}
                              />
                              {errors.lastName && touched.lastName ? (
                                <div>{errors.lastName}</div>
                              ) : null}
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} md={6}>
                            <Form.Group
                              className='mb-3'
                              controlId='exampleForm.ControlInput1'
                            >
                              <Form.Label>Filter by Speciality</Form.Label>
                              <Form.Control
                                name='speciality'
                                onChange={handleChange}
                                value={values.speciality}
                                as='select'
                              >
                                <option value='' label='Select Speciality' />
                                <option value='general' label='General' />
                                <option value='colorectal' label='Colorectal' />
                                <option
                                  value='plastics-reconstructive'
                                  label='Plastics/Reconstructive'
                                />
                                <option value='endocrine' label='Endocrine' />
                                <option value='vascular' label='Vascular' />
                                <option
                                  value='cardio-thoracic'
                                  label='Cardio/Thoracic'
                                />
                                <option value='neuro' label='Neuro' />
                                <option
                                  value='hepatobiliary'
                                  label='Hepatobiliary'
                                />
                                <option value='gynecology' label='Gynecology' />
                                <option
                                  value='other-undetermined'
                                  label='Other/Undetermined'
                                />
                              </Form.Control>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={6}>
                            {value?.id === 'us' ? (
                              <Form.Group
                                className='mb-3'
                                controlId='exampleForm.ControlInput1'
                              >
                                <Form.Label>U.S. ZIP Code</Form.Label>
                                <Form.Control
                                  name='zipcode'
                                  type='number'
                                  maxLength='5'
                                  onChange={handleChange}
                                  value={values.zipcode}
                                />
                                {errors.zipcode && touched.zipcode ? (
                                  <div>{errors.zipcode}</div>
                                ) : null}
                              </Form.Group>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                        <Row>
                          {/* <Col xs={12} md={6}> */}
                          {/* <Container className='pe-0 ps-3'>
                              <Row> */}
                          <Col xs={12} md={6}>
                            <Form.Group controlId='exampleForm.ControlInput1'>
                              <CustomButton
                                variant='primary'
                                // type="submit"
                                onClick={() => {
                                  handleSubmitValue(value);
                                  handleSubmit();
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faSearch}
                                  style={{ fontSize: '16px' }}
                                />{' '}
                                Search
                              </CustomButton>
                            </Form.Group>
                          </Col>
                          <Col xs={12} md={6}>
                            {/* <Form.Group>
                                <CustomButtonAll
                                  variant='primary'
                                  // type="submit"
                                  name='searchUS'
                                  onClick={() => {
                                    handleSubmitValue('world');
                                    handleSubmit();
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faSearch}
                                    style={{ fontSize: '16px' }}
                                  />{' '}
                                  Outside the US? Click Here
                                </CustomButtonAll>
                              </Form.Group> */}
                          </Col>
                          {/* </Row>
                            </Container> */}
                          {/* </Col> */}
                        </Row>
                      </form>
                    )}
                  </Formik>
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs={12} lg='4'>
            <Container>
              <Row>
                <Col>
                  <Box
                    style={{
                      backgroundColor: '#e6edde',
                      padding: '35px 15px 35px 15px',
                    }}
                  >
                    <p>
                      <strong>Privacy Disclaimer:</strong>
                    </p>
                    <p
                      className='smaller'
                      style={{ fontSize: '.7em', lineHeight: '1.3em' }}
                    >
                      We do not collect, share, sell, or otherwise disclose your
                      personal information. This site is designed as a simple
                      geographical look up for surgeons who use fluorescence
                      imaging in their surgical practices. We do not collect any
                      personal information from you and your inquiry is
                      anonymous and protected health information (“PHI”) under
                      the U.S. Health Insurance Portability and Accountability
                      Act (“HIPAA”) is not required for use on this site. If you
                      have any questions about your health privacy rights, see
                      the Notice of Privacy Practices provided to you by your
                      healthcare provider for more information. By using the
                      geographical search you are consenting to our collection
                      and use of zip code search information for the purposes of
                      providing you with a list of surgeons within your area.
                      Please note that this site does not provide any reps or
                      warrants for any service and disclaims any liability for
                      care rendered by any physician listed on this site. The
                      sole use of this site is to provide consumers with a
                      geographical list of surgeons who utilize fluorescence
                      imaging in their practice.
                    </p>
                  </Box>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default SearchView;
