import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import styled from "styled-components";
import SimpleMap from "../GoogleMap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { fatw } from '@fortawesome/free-solid-svg-icons'
import {
  faTwitter,
  faFacebook,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import { setLanguage } from "react-geocode";
import defaultImage from '../../Images/ISFGS-SurgeonPlaceholder.png';

var Loader = require("react-loader");

const ImageWrap = styled.div`
  margin-bottom: 35px;
`;

const Image = styled.div`
  img {
    width: 200px;
  }
`;

const ExcerpWrap = styled.div`
  margin-bottom: 35px;

  & p {
    margin-bottom: 0px;
  }
`;

const SocialWrap = styled.div`
  margin-bottom: 35px;

  & p {
    margin-bottom: 0px;
  }
`;

const MapWrap = styled.div`
  margin-bottom: 35px;
  /*height: 415px;*/
`;

const LocationWrap = styled.div`
  width: 80%;

  & p {
    font-size: 14.4px;
    margin: 0px;
  }
`;

const Contact = styled.span`
  color: #0d96b5;

  a {
    color: #0d96b5;
    font-size: 14.4px;
  }
`;

const Website = styled.div`
  a {

    color: #0d96b5;
    font-size: 16px;
  }
`;

const MemberBioWrap = styled.div``;

const MemberBioInfo = styled.div``;

const MemberDetailsVIew = ({ member }) => {

  const [memberMeta, setMemberMeta] = useState(member);
  const [loading, setLoading] = useState(true);

  // console.log('memberMeta', memberMeta);

  // console.log('memberMeta',memberMeta);

  const googleGeoAddress = `${member.mepr_address_one} ${member.mepr_address_two} ${member.mepr_address_city} ${member.mepr_address_state} ${member.mepr_address_zip}`;

  const getMemberMetaData = async () => {

    setLoading(false);
    try {
      const domain = process.env.REACT_APP_API_URL_META_DATA_DOMAIN;
      const response = await axios.get(`${domain}/wp-json/mq/v1/members-meta/?username=${member.user_login}`);
      setMemberMeta(response.data[0]);
    } catch (error) {
      // console.log(error);
    }
    setLoading(true);

  }
  useEffect(() => {
    getMemberMetaData();
  },[]);

  const normalizeInput = (value) => {
    // return nothing if no value
    if (!value) return value; 
  
    // only allows 0-9 inputs
    const currentValue = value.replace(/[^\d]/g, '');
    const cvLength = currentValue.length; 
  
    if (value.length) {
  
      // returns: "x", "xx", "xxx"
      if (cvLength < 4) return currentValue; 
  
      // returns: "(xxx)", "(xxx) x", "(xxx) xx", "(xxx) xxx",
      if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`; 
  
      // console.log('currentValue', currentValue);

      if (currentValue.slice(0, 1) === '1') {
        // console.log('IS 1');
        // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
        return `+${currentValue.slice(0, 1)} (${currentValue.slice(1, 4)}) ${currentValue.slice(4, 7)}-${currentValue.slice(7, 12)}`; 
      } 
      
      if (currentValue.slice(0, 1) !== '1') {
        // console.log('IS NOT 1');
        // returns: "(xxx) xxx-", (xxx) xxx-x", "(xxx) xxx-xx", "(xxx) xxx-xxx", "(xxx) xxx-xxxx"
        return `+1 (${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 11)}`; 
      }
  }
}


  const options = {
    lines: 13,
    length: 20,
    width: 10,
    radius: 30,
    scale: 1.0,
    corners: 1,
    color: "#000",
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: "50%",
    left: "50%",
    shadow: false,
    hwaccel: false,
    position: "absolute",
  };

  return (
    <React.Fragment>
      <Container>
        <Loader loaded={loading} options={options}>
        <Row>
          <Col>
            <Container style={{ padding: "0px" }}>
              <Row>
                <ImageWrap>
                  <Image>
                  {member && member.mepr_photo ? (
                        <img
                          src={member.mepr_photo}
                          alt="member-image"
                          width={150}
                          // height={250}
                        />
                      ) : (
                        // : <img src="./images/ISFGS-SurgeonPlaceholder.png" alt="member image" width={250} height={250}/>
                        <img
                          src={defaultImage}
                          alt="member-default-image"
                          width={150}
                          // height={250}
                        />
                      )}
                  </Image>
                </ImageWrap>
                <ExcerpWrap>
                  <p>
                    { (memberMeta.profile && memberMeta.profile.mepr_specialty_or_focus && Object.keys(memberMeta.profile.mepr_specialty_or_focus).length) ?
                    <strong>Speciality:</strong> : '' }
                    { (memberMeta.profile && memberMeta.profile.mepr_specialty_or_focus && Object.keys(memberMeta.profile.mepr_specialty_or_focus).length) ?
                      Object.keys(memberMeta.profile.mepr_specialty_or_focus).map( (spes, i) => (
                        <span key={i} style={{ textTransform: 'capitalize'}}> {spes}, </span>
                      ))
                    : ''}
                    <br />
                    { (memberMeta.profile && memberMeta.profile.mepr_languages_spoken && Object.keys(memberMeta.profile.mepr_languages_spoken).length) ?
                    <strong>Languages:</strong> : '' }
                    { (memberMeta.profile && memberMeta.profile.mepr_languages_spoken && Object.keys(memberMeta.profile.mepr_languages_spoken).length) ?
                        Object.keys(memberMeta.profile.mepr_languages_spoken).map( (language, i) => (
                          <span key={i} style={{ textTransform: 'capitalize'}}> {language}, </span>
                        ))
                      : ''}
                  </p>
                </ExcerpWrap>
              </Row>
              <Row>
                <SocialWrap>
                  <p>
                  { ((memberMeta.profile && memberMeta.profile.mepr_twitter_handle) || (memberMeta.profile && memberMeta.profile.mepr_linkedin_url) || (memberMeta.profile && memberMeta.profile.mepr_facebook_url) ) ?
                    <span>Social: </span>
                  : ''}
                    { (memberMeta && memberMeta.profile && memberMeta.profile.mepr_twitter_handle) ?
                    <a href={memberMeta.profile.mepr_twitter_handle} target="_blank">
                      <FontAwesomeIcon
                        icon={faTwitter}
                        style={{
                          fontSize: "20px",
                          color: "#1ebbf0",
                          margin: "0px 4px 0px 4px",
                        }}
                      />
                    </a>
                    : '' }
                     { (memberMeta && memberMeta.profile && memberMeta.profile.mepr_linkedin_url) ?
                    <a href={memberMeta.profile.mepr_linkedin_url} target="_blank">
                      <FontAwesomeIcon
                        icon={faLinkedin}
                        style={{
                          fontSize: "20px",
                          color: "#1ebbf0",
                          margin: "0px 4px 0px 4px",
                        }}
                      />
                    </a>
                    : '' }
                    { (memberMeta && memberMeta.profile && memberMeta.profile.mepr_facebook_url) ?
                    <a href={memberMeta.profile.mepr_facebook_url} target="_blank">
                      <FontAwesomeIcon
                        icon={faFacebook}
                        style={{
                          fontSize: "20px",
                          color: "#1ebbf0",
                          margin: "0px 4px 0px 4px",
                        }}
                      />
                    </a>
                    : '' }
                  </p>
                </SocialWrap>
              </Row>
              <Row>
                {(memberMeta && memberMeta.profile && memberMeta.profile.mepr_bio) ?
                  <MemberBioWrap>
                    <h3>Biography</h3>
                    <MemberBioInfo>{memberMeta.profile.mepr_bio}</MemberBioInfo>
                  </MemberBioWrap>
                : ''}
              </Row>
            </Container>
          </Col>
          <Col>
            <Container style={{ padding: "0px" }}>
              <Row>
                <MapWrap>
                  <SimpleMap address={googleGeoAddress} />
                </MapWrap>
              </Row>
              <Row>
                <LocationWrap>
                  <h3>Location</h3>
                  <p>
                    {(memberMeta && memberMeta.profile && memberMeta.profile.mepr_hospital_institution_company_name) ? `${memberMeta.profile.mepr_hospital_institution_company_name}`: ''}
                    {(memberMeta && memberMeta.profile && memberMeta.profile.mepr_hospital_institution_company_name) ? <br/> : ''}
                    {(memberMeta && memberMeta.address && memberMeta.address['mepr-address-one']) ? `${memberMeta.address['mepr-address-one']}${", "}`: ''}
                    {(memberMeta && memberMeta.address && memberMeta.address['mepr-address-two']) ? `${memberMeta.address['mepr-address-two']}` : ''} 
                    <br/>
                    {(memberMeta && memberMeta.address && memberMeta.address['mepr-address-city']) ? `${memberMeta.address['mepr-address-city']}${", "}`        : ''} 
                    {(memberMeta && memberMeta.address && memberMeta.address['mepr-address-state']) ? `${memberMeta.address['mepr-address-state']}${", "}`      : ''}
                    {(memberMeta && memberMeta.address && memberMeta.address['mepr-address-zip']) ? `${memberMeta.address['mepr-address-zip']}${", "}` : ''} 
                    {(memberMeta && memberMeta.address && memberMeta.address['mepr-address-country']) ? `${memberMeta.address['mepr-address-country']}` : ''}
                    <br />
                     { (member?.country) ? member?.country : '' }
                  </p>
                  <Contact>
                    Appointment:{" "}
                    {/* // previous:: <a href={`tel:${member.mepr_phone}`}>{(memberMeta && memberMeta.profile && memberMeta.profile.mepr_phone) ?  normalizeInput(memberMeta.profile.mepr_phone) : ''}</a> */}
                    <a href={`tel:${member?.mepr_phone ? member.mepr_phone : '' }`}>
                        {
                      // (memberMeta && memberMeta.profile && memberMeta.profile.mepr_phone) ? () => 
                        (member?.mepr_address_country === 'US') ? normalizeInput(member.mepr_phone) : member.mepr_phone
                      // : ''
                      }
                    </a>
                  </Contact>
                  { ( member && member.mepr_website_or_hospital_profile_url ) ?
                  <Website>
                    <strong><a href={member.mepr_website_or_hospital_profile_url} target="_blank" rel="noopener">Website <i class="fas fa-external-link"></i></a></strong>
                  </Website>
                  : '' }
                </LocationWrap>
              </Row>
            </Container>
          </Col>
        </Row>
        </Loader>
      </Container>
    </React.Fragment>
  );
};

export default MemberDetailsVIew;
