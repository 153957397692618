import React, { Component, useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import Geocode from "react-geocode";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapPin } from '@fortawesome/free-solid-svg-icons'

const AnyReactComponent = ({ text }) => <div>{text}</div>;



const SimpleMap = ({address}) => {

    // console.log('address', address);

    const [geoaddress, setGeoAddres] = useState(address);
    const [lat, setLat] = useState();
    const [lng, setLng] = useState();


    const getAddress = (geoaddress) => {
      // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.
      Geocode.setApiKey(process.env.REACT_APP_GOOGLE_API_KEY);
      // set response language. Defaults to english.
      Geocode.setLanguage("en");
      // And according to the below google docs in description, ROOFTOP param returns the most accurate result.
      Geocode.setLocationType("ROOFTOP");
      // Enable or disable logs. Its optional.
      //Geocode.enableDebug();
      
      // Get latitude & longitude from address.
      Geocode.fromAddress(address).then(
          (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            // console.log('LAT AND LONG',lat, lng);
            setLat(lat);
            setLng(lng);
          },
          (error) => {
            console.error(error);
          }
        );
    }


    useEffect(() => {
      getAddress(geoaddress);
    }, []);


  const coordinates = {
    center: {
      lat: lat,
      lng: lng
    },
    zoom: 11
  };

  const renderMarkers = (map, maps) => {
    let marker = new maps.Marker({
    position: { lat: lat, lng: lng },
    map,
    // title: 'Hello World!'
    });
    return marker;
   };
  
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '350px', width: '100%' }}>
          { (lat && lng) ? 
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
          defaultCenter={coordinates.center}
          defaultZoom={coordinates.zoom}
          yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
        >
            
            {/* <FontAwesomeIcon lat={lat} lng={lng} icon={faMapPin} style={{fontSize: '40px'}} /> */}
        </GoogleMapReact>
        : '' }
      </div>
    );
}

export default SimpleMap;