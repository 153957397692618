import React, { useEffect, useState, useRef } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import SearchResultList from "../MembersView";
import ModalView from "../Modal";
import SearchView from "../SearchView";
import axios from "axios";
import {Link, Element, Events, animateScroll, scrollSpy, scroller} from 'react-scroll'
var Loader = require("react-loader");
const lookup = require('country-code-lookup');



const MainView = ({ appParams }) => {
  const [id, setId] = useState(380);
  const [members, setMembers] = useState();
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({});

  const getMembers = async (formdata) => {
    setFormValues(formdata);
    const name = (formdata.lastName) ? formdata.lastName : '';
    const zipcode = (formdata.zipcode) ? formdata.zipcode : '';
    const speciality = (formdata.speciality) ? formdata.speciality : '';
    const searchType = (formdata.search) ? formdata.search : '';

    // lets first construct the api call
    const limit = 20;
    const offset = 0;
    const id = 380;
    const domain = process.env.REACT_APP_API_GET_MEMBERS;
    let apiString = `${domain}/wp-json/mq/v1/members-search/?id=${id}`;
    if (name) apiString += `&name=${name}`;
    if (zipcode) apiString += `&zipcode=${zipcode}`;
    if (speciality) apiString += `&speciality=${speciality}`;
    if (searchType) apiString += `&s=${searchType.id}`;

    // console.log('apiString', apiString);

    setLoading(false);
    try {
      const response = await axios.get(apiString);
      // console.log("RESPONSE", response);

      if (response.data.code === 'no_members') {
        setMembers(false);
      } else {

        // console.log('response.data', response.data);

        const members = response.data.map( (member, key) => {

          let country = '';
          if (member.mepr_address_country) {
            country = member.mepr_address_country ? lookup.byIso(member.mepr_address_country) : ''
            // console.log('COUNTRY', country);
          }

          return member = {
            ...member, country: country?.country,
          }

        });

        setMembers(members);
      }
        
    } catch (error) {
      console.error(error);
    }
    setLoading(true);
    handleScroll();
    // animateScroll.scrollTo('myScrollToElement');
  };

  const myRef = useRef(null);

  const handleScroll = () => myRef.current.scrollIntoView();

  const handleSubmit = (formData) => {
    getMembers(formData);
  };

  const options = {
    lines: 13,
    length: 20,
    width: 10,
    radius: 30,
    scale: 1.0,
    corners: 1,
    color: "#000",
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    top: "50%",
    left: "50%",
    shadow: false,
    hwaccel: false,
    position: "absolute",
  };

  function handleBackClick() {
    // searchRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <React.Fragment>
      <Container className="p-0">
        <Row>
          <Col xs>
            <SearchView
              handleSubmit={handleSubmit}
              formValues={formValues}
              appParams={appParams}
            />
          </Col>
        </Row>
       <hr />
        <Row>
          <Col xs>
          <div ref={myRef}></div> 
            <Loader loaded={loading} options={options}>
              <SearchResultList members={members} />
            </Loader>
            {/* </div> */}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default MainView;
